<!-- 资产管理-部门资产-资产维护 -->
<template>
    <div>
        <global-page-back
            detailPageTitle="资产维护"
            @handlerGlobalType="clickBackBtn"
        >
        </global-page-back>
        <div
            v-if="!errorShow"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <template v-if="!showAddEdit">
                    <!-- 顶部筛选 -->
                    <div class="filter-wrap">
                        <expand-filter
                            :formData="formData"
                            :closeWidth="850"
                            marginBottom="0px"
                            @changeBtnFormType="changeBtnFormType"
                            @changeSel="changeSel"
                            @clickBtn="clickBtn"
                        ></expand-filter>
                        <div class="button-line"></div>
                        <el-button
                                type="enquiry"
                                @click="exportAll"
                            >
                                导出
                            </el-button>
                    </div>

                    <div class="operation-btn-view">
                        <div class="info">
                            <span class="view-label">当前数量: </span>
                            <span class="view-value">{{ amountQuantity.count }}</span>
                            <span class="view-label">总金额:</span>
                            <span class="view-value">{{
                                    amountQuantity.total
                                        ? amountQuantity.total.toLocaleString()
                                        : ""
                                }}元</span>
                        </div>
                    </div>
                    <!-- 表格 -->
                    <table-data
                        id="table"
                        ref="table"
                        :key="tableKey"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                    >
                        <template v-slot:operation="slotData">
                            <el-button
                                type="success"
                                @click="returnContent(slotData.data)"
                                >归还</el-button
                            >
                            <el-button
                                type="success"
                                @click="reportRepair(slotData.data)"
                                >报修</el-button
                            >
                            <el-button
                                type="success"
                                @click="scrapped(slotData.data)"
                                >报废</el-button
                            >
                            <el-button
                                type="danger"
                                @click="details(slotData.data)"
                                >详情</el-button
                            >
                        </template>
                    </table-data>
                    <!-- 分页组件 -->
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </template>
                <!-- 归还弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjReturnContent"
                    @handleClose="closeDialogReturnContent"
                    class="form-dialog"
                >
                    <return-content
                        v-if="dialogObjReturnContent.dialogVisible"
                        :radio="radio"
                        :list="renderingList"
                        :showForm="showForm"
                        :ruleFormReturnContent="ruleFormReturnContent"
                        :rulesReturnContent="rulesReturnContent"
                        :tableData="returnContentTableData"
                        :teacherList="teacherList"
                        @getCurrentRow="getCurrentRow"
                        @submitFormReturnContent="submitFormReturnContent"
                        @resetFormReturnContent="resetFormReturnContent"
                    >
                    </return-content>
                </dialog-wrapper>
                <!-- 报修弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjReportRepair"
                    @handleClose="closeDialogReportRepair"
                    class="form-dialog"
                >
                    <report-repair
                        v-if="dialogObjReportRepair.dialogVisible"
                        :radio="radio"
                        :list="renderingList"
                        :showForm="showForm"
                        :ruleFormReportRepair="ruleFormReportRepair"
                        :rulesReportRepair="rulesReportRepair"
                        :tableData="rulesReportRepairTableData"
                        :teacherList="teacherList"
                        @getCurrentRow="getCurrentRow"
                        @submitFormReportRepair="submitFormReportRepair"
                        @resetFormReportRepair="resetFormReportRepair"
                    >
                    </report-repair>
                </dialog-wrapper>
                <!-- 报废弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjScrapped"
                    @handleClose="closeDialogScrapped"
                    class="form-dialog"
                >
                    <Scrapped
                        v-if="dialogObjScrapped.dialogVisible"
                        :radio="radio"
                        :list="renderingList"
                        :showForm="showForm"
                        :ruleFormScrapped="ruleFormScrapped"
                        :rulesScrapped="rulesScrapped"
                        :tableData="rulesScrappedTableData"
                        :teacherList="teacherList"
                        @getCurrentRow="getCurrentRow"
                        @submitFormScrapped="submitFormScrapped"
                        @resetFormScrapped="resetFormScrapped"
                    />
                </dialog-wrapper>
                <!-- 详情弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjDetails"
                    @handleClose="closeDialogDetails"
                    class="form-dialog"
                >
                    <Details
                        :renderingDetailList="renderingDetailList"
                        :renderingDetailList1="renderingDetailList1"
                        :showForm="showForm"
                        :tableConfig="detailTableConfig"
                        :tableData="detailTableData"
                    />
                </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    debounce,
} from "common-local";
import {downloadFile} from "@/libs/utils.js";

import {mapState} from "vuex";
// 自定义颜色
import btnSty from "@/mixins/btnSty.js";
import TableData from "../Sub/TableData"
import OperationBtnView from "./AssetMaintenance/OperationBtnView.vue";
import ReturnContent from "./AssetMaintenance/ReturnContent.vue";
import ReportRepair from "./AssetMaintenance/ReportRepair.vue";
import Scrapped from "./AssetMaintenance/Scrapped.vue";
import Details from "./AssetMaintenance/Details.vue";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import onResize from "@/mixins/onResize"

// import { CampusSafetyModel } from "@/models/CampusSafety"
import {AssetMaintenanceModel} from "@/models/AssetMaintenance.js";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";

export default {
    mixins: [onResize],
    name: "AssetMaintenanceScrollWrapper",
    components: {
        GlobalPageBack,
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        OperationBtnView,
        ReturnContent,
        ReportRepair,
        Scrapped,
        Details,
        ExpandFilter
    },
    props: {
        assetMaintenance: Object,
    },
    data() {
        return {
            radio: "",
            tableKey: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                deptId: "",
                schoolId: "",
                productId: "",
                storeId: "",
                categoryId: "",
                assetsName: "",
                assetsCode: "",
                assetsType: "",
            },
            assetClassList: {
                schoolId: "",
                storeId: "",
            },
            amountQuantity: {
                count: "",
                total: "",
            },
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "仓库名称",
                        key: "storeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "资产品类",
                        key: "categoryId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "资产名称",
                        key: "assetsName",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "资产编号",
                        key: "assetsCode",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "资产类型",
                        key: "assetsType",
                        list: [
                            {
                                label: "全部",
                                value: "",
                            },
                            {
                                label: "固定资产",
                                value: 1,
                            },
                            {
                                label: "耗材资产",
                                value: 2,
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                check: false,
                thead: [
                    {
                        label: "所属仓库",
                        prop: "storeName",
                        type: "popoverText",
                        align: 'center'
                    },
                    {
                        label: "资产品类",
                        prop: "categoryName",
                        type: "popoverText",
                        align: 'center'
                    },
                    {
                        label: "资产名称",
                        prop: "assetsName",
                        align: 'center'
                    },
                    {
                        label: "资产编号",
                        prop: "assetsCode",
                        align: 'center'
                    },
                    {
                        label: "资产数量",
                        prop: "assetsCount",
                        align: "center"
                    },
                    {
                        type: "function",
                        label: "资产总额",
                        labelDescription: "单位：元",
                        align: "right",
                        prop: "assetsTotal",
                        labelWidth: '120px',
                        callBack(row) {
                            if (row.assetsTotal) {
                                return row.assetsTotal.toLocaleString();
                            } else {
                                return 0;
                            }
                        },
                    },
                    {
                        label: "操作",
                        type: "slot",
                        align: 'center',
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                height: "",
            },
            // 表格数据
            table_data: [],
            // 添加编辑显示
            showAddEdit: false,
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            // 新增
            ruleForm: {
                id: "",
                schoolId: "",
                groupName: "", // 出入组名称
                accessType: "1", // 出入类型  1出校 2入校 3不区分出入校
                accessRule: "1", // 出入规则  1每周 2自定义
                time: "", // 出入时间，辅助星期和日历选择，仅添加时展示
                ruleInfo: [], // 出入时间信息
                organInfo: [], // 选中机构信息
                personInfo: [], // 选中人员信息
                showName: "", // 人员输入框展示使用
                userPopulation: "1",
            },
            // 表格选中
            tableListSel: [],

            /**
             * showForm 弹窗展示不同的表单
             * dialogObjReturnContent 归还弹窗
             * dialogObjReportRepair 报修弹窗
             * dialogObjScrapped 报废弹窗
             * dialogObjDetails 详情弹窗
             * */
            showForm: "",

            ruleFormReturnContent: {
                assetsInId: "",
                operateNumber: "1",
                operateTime: "",
                operateReason: "",
            },
            rulesReturnContent: {
                operateNumber: [
                    {
                        required: true,
                        message: "请填写归还数量",
                        trigger: "blur",
                    },
                ],
            },
            returnContentTableData: [],

            dialogObjReturnContent: {
                title: "资产归还",
                dialogVisible: false,
                width: "642px",
                key: 1,
            },

            ruleFormReportRepair: {
                schoolId: "",
                storeId: "",
                assetsId: "",
                assetsCode: "",
                assetsName: "",
                fixContent: "",
                operateNumber: "",
                fixAmount: "",
                fixPerson: "",
                declarer: "",
                assetsUnit: "",
                emergencyLevel: "",
                deptAssetsInId: "",
                assetsType: "",
                assetsBrand: "",
                assetsModel: "",
                categoryName: "",
                assetsSerialNumber: "",
                deptId: "",
            },
            rulesReportRepair: {
                operateNumber: [
                    {
                        required: true,
                        message: "请填写归还数量",
                        trigger: "blur",
                    },
                ],
                fixAmount: [
                    {
                        required: true,
                        message: "请填写维修金额",
                        trigger: "blur",
                    },
                ],
                fixPerson: [
                    {required: true, message: "请填写维修人", trigger: "blur"},
                ],
            },
            rulesReportRepairTableData: [],
            dialogObjReportRepair: {
                title: "资产报修",
                dialogVisible: false,
                width: "642px",
                key: 1,
            },

            ruleFormScrapped: {
                assetsInId: "",
                operateNumber: "1",
                operateTime: "",
                operateReason: "",
            },
            rulesScrapped: {
                operateNumber: [
                    {
                        required: true,
                        message: "请填写报废数量",
                        trigger: "blur",
                    },
                ],
            },
            rulesScrappedTableData: [],
            dialogObjScrapped: {
                title: "资产报废",
                dialogVisible: false,
                width: "642px",
                key: 1,
            },

            detailTableConfig: {
                check: false,
                thead: [
                    {
                        label: "日期",
                        prop: "operateTime",
                        align: 'left',
                        labelWidth: '180px'
                    },
                    {
                        label: "操作人",
                        prop: "createName",
                        align: 'left'
                    },
                    {
                        label: "变动类型",
                        prop: "recordTypeName",
                        align: 'center'
                    },
                    {
                        label: "详情",
                        prop: "info",
                        align: 'left'
                    },
                ],
            },

            detailTableData: [],

            dialogObjDetails: {
                title: "资产详情",
                dialogVisible: false,
                width: "800px",
                key: 1,
            },

            renderingList: {
                assetsCode: "",
                assetsName: "",
                assetsUnit: "",
            },
            renderingDetailList: {
                assetsCode: "",
                assetsName: "",
                storeName: "",
                categoryName: "",
                assetsType: "",
                assetsBrand: "",
                assetsModel: "",
                assetsSerialNumber: "",
                assetsUnit: "",
            },

            renderingDetailList1: {
                assetsCount: "",
                assetsTotal: "",
                repairTimes: "",
                repairTotal: "",
                returnQuantity: "",
                returnTotal: "",
                scrapQuantity: "",
                scrapTotal: "",
            },
            // 教师数据
            teacherList: [],
            categoryList: [],
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            state: (state) => state,
        })
    },
    async created() {
        await this.initializePageData(this.assetMaintenance);
        await this.getAllUserList();
        await this.getSchoolStoreCategory();
        await this.getList();
    },
    mounted() {
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.$refs.table) {
            this.$refs.table.$refs.multipleTable.doLayout();
        }
    },
    methods: {
        /**
         *  初始化页面数据
         *  initializePageData
         * */
        async initializePageData(data) {
            this.listQuery.schoolId = this.schoolId;
            this.listQuery.productId = "cloud_campus";
            this.assetClassList.schoolId = this.schoolId;
            this.ruleFormReportRepair.deptId = this.assetMaintenance.deptId;

            Object.keys(this.listQuery).forEach((key) => {
                Object.keys(data).forEach((subKey) => {
                    if (key === subKey) {
                        this.listQuery[key] = data[subKey];
                    }
                });
            });

            const assetMaintenanceModel = new AssetMaintenanceModel();
            await assetMaintenanceModel
                .getWarehouseName({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code === "200") {
                        res.data.data.forEach((item) => {
                            let obj = {
                                label: item.storeName,
                                value: item.id,
                            };
                            this.formData.data.forEach((item) => {
                                if (item.key === "storeId") {
                                    item.list.push(obj);
                                }
                            });
                        });
                    }
                });
        },
        // 获取全部教职工信息
        async getAllUserList() {
            await this._fet("/school/schoolOrgan/getIssueScopeByAccess", {
                schoolId: this.schoolId,
                applyPerson: "1",
            }).then((res) => {
                if (res.data.code == "200") {
                    console.log(res.data.data, "获取全部教职工信息");
                    this.setDisabledOption(res.data.data);
                    this.teacherList = res.data.data;
                    console.log(this.teacherList, "获取全部教职工信息");
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-28 10:09:29
         */
        setDisabledOption(list) {
            list.forEach((item) => {
                if (item.key !== "teacher") {
                    this.$set(item, "disabled", true);
                } else {
                    this.$set(item, "disabled", false);
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption(item.children);
                }
            });
        },
        /**
         * @Description: 获取资产品类
         * @DoWhat: 获取资产品类
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2022-12-07 15:21:12
         */
        getSchoolStoreCategory() {
            console.log(this.assetMaintenance, "assetMaintenance");
            this._fet("/school/schoolStoreCategory/listByCondition", {
                schoolId: this.schoolId,
                storeId: "",
            }).then((res) => {
                this.handleRes(res, () => {
                    this.categoryList = res.data.data.map((i) => ({
                        label: i.categoryName,
                        value: i.id,
                    }));
                    this.formData.data.forEach((item) => {
                        if (item.key === "categoryId") {
                            item.list = this.categoryList;
                        }
                    });
                });
            });
        },
        getList(t) {
            this.loadingTable = true;
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }

            let assetMaintenanceModel = new AssetMaintenanceModel();
            assetMaintenanceModel
                .getAssetMaintenanceList(this.listQuery)
                .then((res) => {
                    this.handleRes(res, () => {
                        console.log(res, "res");
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        this.amountQuantity.count = res.data.data.count;
                        this.amountQuantity.total = res.data.data.total;
                        console.log(this.amountQuantity, "this.amountQuantity");
                        this.tableKey = this.tableKey + 1;
                        this.loadingTable = false;
                    });
                });
        },

        // 关闭弹窗组件
        closeDialog() {
            this.dialogObj.dialogVisible = false;
        },

        // 选中
        handleSelectionChange(data) {
            this.tableListSel = data;
        },
        // 导出
        exportAll() {
            let obj = {
                deptId: "",
                schoolId: this.schoolId,
                productId: "cloud_campus",
                storeId: "",
                categoryId: "",
                assetsName: "",
                assetsCode: "",
                assetsType: "",
            };

            Object.keys(obj).forEach((key) => {
                Object.keys(this.listQuery).forEach((subKey) => {
                    if (key === subKey) {
                        obj[key] = this.listQuery[subKey];
                    }
                });
            });

            console.log(obj, "obj");
            downloadFile(
                {url: "/school/schoolDeptAssets/export", form: obj},
                () => {
                    this.$message.success("导出成功");
                },
                () => {},
            );
        },

        /**
         * *筛选表单
         * changeSel 选择
         * clickBtn 点击查询
         * */
        changeSel(data) {
            if (data.key === "storeId") {
                this.assetClassList.storeId = data.value;

                this.formData.data.forEach((item) => {
                    if (item.key === "categoryId") {
                        item.list = [];
                        item.value = "";
                        this.listQuery.categoryId = "";
                    }
                });

                const assetMaintenanceModel = new AssetMaintenanceModel();
                assetMaintenanceModel
                    .getAssetClassList(this.assetClassList)
                    .then((res) => {
                        if (res.data.code === "200") {
                            res.data.data.forEach((item) => {
                                let obj = {
                                    label: item.categoryName,
                                    value: item.id,
                                };
                                this.formData.data.forEach((item) => {
                                    if (item.key === "categoryId") {
                                        item.list.push(obj);
                                    }
                                });
                            });
                        } else {
                        }
                    });
            }
        },
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.getList(1);
                    break;
            }
        },
        // 返回部门资产
        clickBackBtn(){
            this.$emit("backList");
        },
        // 添加编辑返回
        back() {},

        /**
         * 表格按钮操作
         * returnContent 归还
         * reportRepair 报修
         * scrapped 报废
         * details 详情
         *
         * getCurrentRow 详情表格单选
         *
         * submitFormReturnContent 归还提交
         * resetFormReturnContent 归还关闭
         *
         * submitFormReportRepair 报修提交
         * resetFormReportRepair 报修关闭
         *
         * submitFormScrapped 报废提交
         * resetFormScrapped 报废关闭
         *
         * closeDialogReturnContent 关闭归还弹窗
         * closeDialogReportRepair 关闭报修弹窗
         * closeDialogScrapped 关闭报废弹窗
         * closeDialogDetails 关闭详情弹窗
         *
         * getDialogWrapperDetail 获取弹窗详情
         * */

        async returnContent(data) {
            this.showForm = "returnContent";

            let operationDetails = {
                assetsId: data.id,
                schoolId: data.schoolId,
                storeId: data.storeId,
                deptId: data.deptId,
            };

            this.returnContentTableData = await this.getDialogWrapperDetail(
                operationDetails,
            );
            this.dialogObjReturnContent.dialogVisible = true;
        },
        async reportRepair(data) {
            console.log(data, "data");

            this.showForm = "reportRepair";
            let operationDetails = {
                assetsId: data.id,
                schoolId: data.schoolId,
                storeId: data.storeId,
                deptId: data.deptId,
            };
            // 报修id
            this.ruleFormReportRepair.assetsType = data.assetsType;
            this.ruleFormReportRepair.assetsType = data.assetsType;
            this.ruleFormReportRepair.deptId = data.deptId;

            this.rulesReportRepairTableData = await this.getDialogWrapperDetail(
                operationDetails,
            );
            this.dialogObjReportRepair.dialogVisible = true;
        },
        async scrapped(data) {
            this.showForm = "scrapped";

            let operationDetails = {
                assetsId: data.id,
                schoolId: data.schoolId,
                storeId: data.storeId,
                deptId: data.deptId,
            };
            this.rulesScrappedTableData = await this.getDialogWrapperDetail(
                operationDetails,
            );
            this.dialogObjScrapped.dialogVisible = true;
        },
        details(data) {
            this.showForm = "details";
            console.log(data, "data123");

            const assetMaintenanceModel = new AssetMaintenanceModel();

            assetMaintenanceModel.assetDetail({id: data.id}).then((res) => {
                if (res.data.code === "200") {
                    Object.keys(res.data.data).forEach((subKey) => {
                        Object.keys(this.renderingDetailList).forEach((key) => {
                            if (subKey == key) {
                                this.renderingDetailList[key] =
                                    res.data.data[subKey];
                            }
                        });

                        switch (this.renderingDetailList.assetsType) {
                            case "1":
                                this.renderingDetailList.assetsType =
                                    "固定资产";
                                break;
                            case "2":
                                this.renderingDetailList.assetsType =
                                    "耗材资产";
                                break;
                            default:
                                break;
                        }

                        Object.keys(this.renderingDetailList1).forEach(
                            (key) => {
                                if (subKey == key) {
                                    this.renderingDetailList1[key] =
                                        res.data.data[subKey];
                                }
                            },
                        );
                    });

                    this.detailTableData = res.data.data.result;

                    this.detailTableData.forEach((item) => {
                        switch (item.recordType) {
                            case 1:
                                this.$set(item, "recordTypeName", "入库");
                                break;
                            case 2:
                                this.$set(item, "recordTypeName", "出库");
                                break;
                            case 3:
                                this.$set(item, "recordTypeName", "保修");
                                break;
                            case 4:
                                this.$set(item, "recordTypeName", "归还");
                                break;
                            case 5:
                                this.$set(item, "recordTypeName", "报废");
                                break;
                            default:
                                break;
                        }
                    });

                    console.log(
                        "12",
                        "--=",
                        this.renderingDetailList,
                        this.renderingDetailList1,
                        "--",
                    );
                    // this.detailTableData

                    this.dialogObjDetails.dialogVisible = true;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });

            //
        },

        getCurrentRow(data) {
            console.log(this.showForm, data, "this.showForm)");
            switch (this.showForm) {
                case "returnContent":
                    this.ruleFormReturnContent.assetsInId = data.id;
                    break;
                case "reportRepair":
                    Object.keys(data).forEach((key) => {
                        Object.keys(this.ruleFormReportRepair).forEach(
                            (subKey) => {
                                if (key === subKey) {
                                    this.ruleFormReportRepair[subKey] =
                                        data[key];
                                }
                            },
                        );
                    });
                    this.ruleFormReportRepair.deptAssetsInId = data.id;
                    this.ruleFormReportRepair.schoolId = this.schoolId;
                    break;
                case "scrapped":
                    this.ruleFormScrapped.assetsInId = data.id;
                    break;
            }
        },

        submitFormReturnContent() {
            // 提交的数据
            console.log(this.ruleFormReturnContent, "归还--提交的数据");
            if (!this.ruleFormReturnContent.assetsInId) {
                this.$message.error("请选择归还物品");
                return;
            }

            const assetMaintenanceModel = new AssetMaintenanceModel();

            assetMaintenanceModel
                .assetRepatriation(this.ruleFormReturnContent)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("归还成功");
                        this.getList();
                        this.radio = "";
                        this.resetFormReturnContent();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        resetFormReturnContent() {
            console.log(this.radio, "this.radio123");
            this.ruleFormReturnContent = {
                assetsInId: "",
                operateNumber: "1",
                operateTime: "",
                operateReason: "",
            };
            this.closeDialogReturnContent();
        },

        submitFormReportRepair() {
            console.log(this.ruleFormReportRepair, "ruleFormReportRepair");
            if (!this.ruleFormReportRepair.deptAssetsInId) {
                this.$message.error("请选择报修物品");
                return;
            }

            const assetMaintenanceModel = new AssetMaintenanceModel();

            assetMaintenanceModel
                .assetRepair(this.ruleFormReportRepair)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("报修成功");
                        this.radio = "";
                        this.getList();
                        this.resetFormReportRepair();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        resetFormReportRepair() {
            (this.ruleFormReportRepair = {
                deptAssetsInId: "",
                storeId: "",
                assetsId: "",
                assetsCode: "",
                assetsName: "",
                fixContent: "",
                operateNumber: "1",
                fixAmount: "",
                fixPerson: "",
                declarer: "",
                assetsUnit: "",
                emergencyLevel: "",
                deptAssetsInId: "",
                assetsType: "",
                assetsBrand: "",
                assetsModel: "",
                categoryName: "",
                assetsSerialNumber: "",
                deptId: "",
            }),
                this.closeDialogReportRepair();
        },

        closeDialogReturnContent() {
            this.dialogObjReturnContent.dialogVisible = false;
        },
        closeDialogReportRepair() {
            this.dialogObjReportRepair.dialogVisible = false;
        },

        submitFormScrapped() {
            if (!this.ruleFormScrapped.assetsInId) {
                this.$message.error("请选择报废物品");
                return;
            }

            const assetMaintenanceModel = new AssetMaintenanceModel();

            assetMaintenanceModel
                .assetRetirement(this.ruleFormScrapped)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.$message.success("报废成功");
                        this.radio = "";
                        this.resetFormScrapped();
                        this.getList();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        resetFormScrapped() {
            (this.ruleFormScrapped = {
                assetsInId: "",
                operateNumber: "1",
                operateTime: "",
                operateReason: "",
            }),
                this.closeDialogScrapped();
        },

        closeDialogScrapped() {
            this.dialogObjScrapped.dialogVisible = false;
        },
        closeDialogDetails() {
            this.dialogObjDetails.dialogVisible = false;
        },
        async getDialogWrapperDetail(data) {
            let list = [];
            const assetMaintenanceModel = new AssetMaintenanceModel();
            await assetMaintenanceModel
                .getOperationPopupDetails(data)
                .then((res) => {
                    if (res.data.code === "200") {
                        Object.keys(res.data.data).forEach((key) => {
                            Object.keys(this.renderingList).forEach(
                                (subKey) => {
                                    if (key == subKey) {
                                        this.renderingList[subKey] =
                                            res.data.data[key];
                                    }
                                },
                            );
                        });
                        list = res.data.data.list;
                    }
                });

            return list;
        },

        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }
}
.operation-btn-view {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    background-color: #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    .view-label {
        font-size: 14px;
        color: #737373;
        font-weight: bold;
    }

    .view-value {
        margin-right: 30px;
        font-weight: bold;
        color: #7a8a99;
    }
}

.form-dialog {
    ::v-deep .el-dialog__body {
        padding-top: 0;
    }
}
</style>
